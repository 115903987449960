import { Link, useSearchParams } from "@remix-run/react";
import clsx from "clsx";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import ButtonEvent from "~/components/ui/buttons/ButtonEvent";
import { HeroBlockDto } from "~/modules/pageBlocks/components/blocks/marketing/hero/HeroBlockUtils";
import LaunchBadges from "../launch/LaunchBadges";
import { AnimatedBeamMultipleInputDemo } from "~/components/magicui/AnimatedBeamDemo";
import AllDemos from "~/modules/datalang/components/demos/AllDemos";

export default function HeroVariantRightImage({ item }: { item: HeroBlockDto }) {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const screenshotMode = searchParams.get("screenshotMode") === "true";
  return (
    <section className="body-font">
      <div className="container mx-auto flex flex-col items-center px-5 py-12 xl:flex-row">
        <div className="flex flex-col items-center text-center xl:mb-0 xl:w-1/2 xl:flex-grow xl:items-start xl:pr-16 xl:text-left">
          <div className="mb-4">
            <LaunchBadges theme="light" />
            {item.topText && (
              <span className="text-muted-foreground block text-sm font-semibold uppercase tracking-wide sm:text-base lg:text-sm xl:text-base">
                {t(item.topText.text ?? "")}{" "}
                {item.topText.link && (
                  <Link to={item.topText.link.href ?? ""} className="text-primary relative font-semibold">
                    <span className="absolute inset-0" aria-hidden="true" />
                    {t(item.topText.link.text ?? "")} <span aria-hidden="true">&rarr;</span>
                  </Link>
                )}
              </span>
            )}
          </div>
          <h1
            className={clsx("title-font mb-4 max-w-3xl text-4xl font-bold sm:text-5xl md:text-6xl lg:text-7xl lg:font-black", screenshotMode && "xl:text-7xl")}
          >
            {t(item.headline)}
          </h1>
          {item.description && !screenshotMode && <h2 className="text-muted-foreground mb-8 max-w-3xl leading-relaxed">{t(item.description)}</h2>}
          <div className="flex w-full flex-col justify-center gap-4 sm:w-auto sm:flex-row">
            {item.cta.map((item, idx) => {
              return (
                <Fragment key={idx}>
                  <ButtonEvent
                    key={idx}
                    event={{ action: "click", category: "hero", label: item.text ?? "", value: item.href ?? "" }}
                    to={item.href}
                    className={clsx(
                      "w-full sm:w-auto",
                      item.isPrimary
                        ? "bg-primary hover:bg-primary/95 text-primary-foreground inline-flex justify-center rounded-md border-0 px-4 py-3 text-lg shadow-sm focus:outline-none"
                        : " bg-secondary hover:bg-secondary/95 text-secondary-foreground inline-flex justify-center rounded-md border-0 px-4 py-3 text-lg shadow-sm focus:outline-none"
                    )}
                  >
                    {t(item.text)}
                  </ButtonEvent>
                </Fragment>
              );
            })}
          </div>
          <div className="mt-8 space-y-3">
            {item.bottomText && (
              <span>
                {t(item.bottomText.text ?? "")}{" "}
                {item.bottomText.link?.href && (
                  <ButtonEvent
                    to={item.bottomText.link.href ?? ""}
                    target={item.bottomText.link.target}
                    className="text-sm hover:underline"
                    event={{ action: "click", category: "hero", label: item.bottomText.link.text ?? "", value: item.bottomText.link.href ?? "" }}
                  >
                    {t(item.bottomText.link.text ?? "")}
                  </ButtonEvent>
                )}
              </span>
            )}
          </div>
        </div>
        <div className="hidden w-5/6 rounded-lg md:w-1/3 xl:block xl:w-full xl:max-w-lg">
          <div className="h-96">
            <AnimatedBeamMultipleInputDemo />
          </div>
        </div>
      </div>

      <div className="container mx-auto mt-10 space-y-4 px-5">
        <AllDemos
          showPremium={true}
          withDescriptions={false}
          className="grid gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6"
          withFeedback={false}
        />
      </div>
    </section>
  );
}
