import { Link, useSearchParams } from "@remix-run/react";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import UserAvatarBadge from "~/components/core/users/UserAvatarBadge";
import ButtonEvent from "~/components/ui/buttons/ButtonEvent";
import { HeroBlockDto } from "~/modules/pageBlocks/components/blocks/marketing/hero/HeroBlockUtils";
import { TestimonialDto } from "../testimonials/TestimonialsBlockUtils";
import NumberUtils from "~/utils/shared/NumberUtils";
import toast from "react-hot-toast";
import ButtonPrimary from "~/components/ui/buttons/ButtonPrimary";
import ButtonSecondary from "~/components/ui/buttons/ButtonSecondary";
import LaunchBadges from "../launch/LaunchBadges";
import AllDemos from "~/modules/datalang/components/demos/AllDemos";
import Logo from "~/components/brand/Logo";
import clsx from "clsx";

export default function HeroVariantBottomImage({ item }: { item: HeroBlockDto }) {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const screenshotMode = searchParams.get("screenshotMode") === "true";
  return (
    <section className="body-font">
      <div className="container mx-auto flex flex-col items-center justify-center px-5 pb-16 pt-4">
        <div className="w-full text-center lg:max-w-3xl xl:max-w-5xl">
          <div className="mb-4">
            <LaunchBadges theme="neutral" />
            {item.topText && (
              <span className="text-muted-foreground block text-sm font-semibold tracking-wide sm:text-base lg:text-sm xl:text-base">
                {t(item.topText.text ?? "")}{" "}
                {item.topText.link && (
                  <Link to={item.topText.link.href ?? ""} className="text-theme-600 relative font-semibold">
                    <span className="absolute inset-0" aria-hidden="true" />
                    {t(item.topText.link.text ?? "")} <span aria-hidden="true">&rarr;</span>
                  </Link>
                )}
              </span>
            )}
          </div>

          {screenshotMode ? (
            <div className="mx-auto flex justify-center pb-4 pt-12">
              <Logo size="h-[70px]" />
            </div>
          ) : (
            <div className="text-primary mx-auto flex justify-center">{/* <Icon size="h-8" /> */}</div>
          )}
          {item.headline && (
            <h1
              className={clsx(
                "title-font mx-auto mb-4 max-w-3xl text-4xl font-bold sm:text-5xl md:text-6xl md:font-black lg:text-7xl",
                screenshotMode && "xl:text-7xl"
              )}
            >
              {t(item.headline)}
            </h1>
          )}
          {item.description && !screenshotMode && <h2 className="mb-8 max-w-6xl text-lg leading-relaxed md:text-2xl">{t(item.description)}</h2>}

          {!screenshotMode && (
            <Fragment>
              <div className="flex flex-col flex-wrap justify-center gap-4 sm:flex-row">
                {item.cta.map((item, idx) => {
                  return (
                    <Fragment key={idx}>
                      {item.isPrimary ? (
                        <ButtonPrimary
                          className="w-full px-4 py-6 text-xl sm:w-auto"
                          event={{
                            action: "click",
                            category: "hero",
                            label: typeof item.text === "string" ? item.text : "button",
                            value: item.href || item.text,
                          }}
                          key={idx}
                          to={item.href}
                          onClick={() => {
                            if (item.tour) {
                              try {
                                if (item.tour.type === "storylane") {
                                  // @ts-ignore
                                  Storylane.Play({
                                    type: "popup",
                                    demo_type: "image",
                                    width: 2560,
                                    height: 1376,
                                    scale: "0.95",
                                    demo_url: item.tour.url,
                                    padding_bottom: "calc(53.75% + 27px)",
                                  });
                                }
                              } catch (e: any) {
                                toast.error("Could not open the product tour demo: " + e.message);
                              }
                            }
                          }}
                        >
                          {t(item.text)}
                        </ButtonPrimary>
                      ) : (
                        <ButtonSecondary
                          className="w-full px-4 py-6 text-xl sm:w-auto"
                          event={{
                            action: "click",
                            category: "hero",
                            label: typeof item.text === "string" ? item.text : "button",
                            value: item.href || item.text,
                          }}
                          key={idx}
                          to={item.href}
                          onClick={() => {
                            if (item.tour) {
                              try {
                                if (item.tour.type === "storylane") {
                                  // @ts-ignore
                                  Storylane.Play({
                                    type: "popup",
                                    demo_type: "image",
                                    width: 2560,
                                    height: 1376,
                                    scale: "0.95",
                                    demo_url: item.tour.url,
                                    padding_bottom: "calc(53.75% + 27px)",
                                  });
                                }
                              } catch (e: any) {
                                toast.error("Could not open the product tour demo: " + e.message);
                              }
                            }
                          }}
                        >
                          {t(item.text)}
                        </ButtonSecondary>
                      )}
                    </Fragment>
                  );
                })}
              </div>
              <div className="mt-4 space-y-3">
                {item.bottomText && (
                  <span>
                    {t(item.bottomText.text ?? "")}{" "}
                    {item.bottomText.link?.href && (
                      <>
                        <ButtonEvent
                          event={{
                            action: "click",
                            category: "hero",
                            label: "bottom-link",
                            value: item.bottomText.link.href ?? "",
                          }}
                          to={item.bottomText.link.href ?? ""}
                          target={item.bottomText.link.target}
                          className="border-accent-500 text-muted-foreground border-b border-dashed text-sm italic hover:border-dotted dark:border-cyan-300"
                        >
                          {t(item.bottomText.link.text ?? "")}
                        </ButtonEvent>
                      </>
                    )}
                  </span>
                )}
                {item.testimonials && <Ratings testimonials={item.testimonials} />}
              </div>
            </Fragment>
          )}
        </div>

        <div className="container mx-auto mt-10 max-w-5xl space-y-4 px-5">
          <AllDemos
            showPremium={true}
            withDescriptions={false}
            // className="grid gap-4 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-5"
            withFeedback={false}
          />
        </div>

        {item.image && (
          <img
            className="mt-10 hidden w-auto max-w-2xl rounded border-2 border-dashed border-gray-800 object-cover object-center md:flex"
            alt={item.headline}
            src={item.image}
          />
        )}
        {item.video && (
          <div className="mx-auto my-12 mt-10 h-96 w-full max-w-3xl overflow-hidden rounded-md border-4 border-gray-800 shadow-lg">
            <iframe
              src={item.video}
              title={item.headline}
              loading="lazy"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
              width="100%"
              height="100%"
            ></iframe>
          </div>
        )}
      </div>
    </section>
  );
}

function Ratings({ testimonials }: { testimonials: TestimonialDto[] }) {
  const avg = testimonials.reduce((acc, curr) => acc + (curr.stars || 5), 0) / testimonials.length;
  return (
    <div className="flex flex-col items-center gap-2 p-4 md:p-6">
      <div className="flex items-center space-x-4">
        {testimonials.map((item, idx) => {
          const url = item.personalWebsite || item.quoteUrl;
          return url ? (
            <ButtonEvent
              event={{
                action: "click",
                category: "hero-testimonial",
                label: item.name,
                value: url,
              }}
              to={url}
              target="_blank"
              rel="noreferrer"
              key={idx}
            >
              <UserAvatarBadge avatar={item.avatar} className="h-9 w-9" />
            </ButtonEvent>
          ) : (
            <UserAvatarBadge key={idx} avatar={item.avatar} className="h-9 w-9" />
          );
        })}
      </div>
      <div className="flex items-center gap-2">
        <StarIcon className="h-5 w-5 text-yellow-400" />
        <StarIcon className="h-5 w-5 text-yellow-400" />
        <StarIcon className="h-5 w-5 text-yellow-400" />
        <StarIcon className="h-5 w-5 text-yellow-400" />
        <StarIcon className="h-5 w-5 text-yellow-400" />
        <ButtonEvent
          event={{
            action: "click",
            category: "hero",
            label: "testimonials",
            value: "https://www.trustpilot.com/review/saasrock.com",
          }}
          to="https://www.trustpilot.com/review/saasrock.com"
          target="_blank"
          rel="noreferrer"
          className="ml-2 flex items-center font-medium hover:underline"
        >
          {/* {NumberUtils.decimalFormat(avg, 1)} from {testimonials.length} happy customers */}
          <p className="ms-2 text-sm font-bold ">{NumberUtils.decimalFormat(avg, 1)}</p>
          <span className="mx-1.5 h-1 w-1 rounded-full"></span>
          <div className="text-sm font-medium ">{testimonials.length} reviews</div>
        </ButtonEvent>
      </div>
    </div>
  );
}

function StarIcon(props: any) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
    </svg>
  );
}

// const Badge = ({ item }: { item: TextWithLinkDto }) => {
//   return (
//     <Fragment>
//       {item.link?.href ? (
//         <Link
//           to={item.link?.href}
//           target={item.link?.target}
//           className="group relative mb-8 inline-block cursor-pointer rounded-full  bg-slate-900 p-px text-xs font-semibold leading-6 text-white no-underline  shadow-2xl shadow-zinc-900"
//         >
//           <span className="absolute inset-0 overflow-hidden rounded-full">
//             <span className="absolute inset-0 rounded-full bg-[image:radial-gradient(75%_100%_at_50%_0%,rgba(56,189,248,0.6)_0%,rgba(56,189,248,0)_75%)] opacity-0 transition-opacity duration-500 group-hover:opacity-100"></span>
//           </span>
//           <div className="relative flex items-center space-x-2 rounded-full bg-zinc-950 px-4 py-0.5 ring-1 ring-white/10 ">
//             <span>{item.text}</span>
//             <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
//               <motion.path
//                 stroke="currentColor"
//                 strokeLinecap="round"
//                 strokeLinejoin="round"
//                 strokeWidth="1.5"
//                 d="M10.75 8.75L14.25 12L10.75 15.25"
//                 initial={{ pathLength: 0 }}
//                 animate={{ pathLength: 1 }}
//                 transition={{ duration: 1 }}
//               ></motion.path>
//             </svg>
//           </div>
//           <span className="absolute -bottom-0 left-[1.125rem] h-px w-[calc(100%-2.25rem)] bg-gradient-to-r from-emerald-400/0 via-emerald-400/90 to-emerald-400/0 transition-opacity duration-500 group-hover:opacity-40"></span>
//         </Link>
//       ) : (
//         <div className="group relative mb-8 inline-block select-none rounded-full  bg-slate-900 p-px text-xs font-semibold leading-6 text-white no-underline  shadow-2xl shadow-zinc-900">
//           <span className="absolute inset-0 overflow-hidden rounded-full">
//             <span className="absolute inset-0 rounded-full bg-[image:radial-gradient(75%_100%_at_50%_0%,rgba(56,189,248,0.6)_0%,rgba(56,189,248,0)_75%)] opacity-0 transition-opacity duration-500 group-hover:opacity-100"></span>
//           </span>
//           <div className="relative flex items-center space-x-2 rounded-full bg-zinc-950 px-4 py-0.5 ring-1 ring-white/10 ">
//             <span>{item.text}</span>
//             <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
//               <motion.path
//                 stroke="currentColor"
//                 strokeLinecap="round"
//                 strokeLinejoin="round"
//                 strokeWidth="1.5"
//                 d="M10.75 8.75L14.25 12L10.75 15.25"
//                 initial={{ pathLength: 0 }}
//                 animate={{ pathLength: 1 }}
//                 transition={{ duration: 1 }}
//               ></motion.path>
//             </svg>
//           </div>
//           <span className="absolute -bottom-0 left-[1.125rem] h-px w-[calc(100%-2.25rem)] bg-gradient-to-r from-emerald-400/0 via-emerald-400/90 to-emerald-400/0 transition-opacity duration-500 group-hover:opacity-40"></span>
//         </div>
//       )}
//     </Fragment>
//   );
// };
