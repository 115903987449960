export default function ImageOrSvg({
  className,
  icon,
  title,
  onClick,
  alt,
}: {
  className?: string;
  icon?: string | null;
  title?: string;
  alt?: string;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}) {
  if (!icon) {
    return null;
  }
  return (
    <>
      {icon.startsWith("<svg") ? (
        <div onClick={onClick} dangerouslySetInnerHTML={{ __html: icon.replace("<svg", `<svg class='${className}'`) ?? "" }} />
      ) : icon.includes("http") ? (
        <img alt={alt || title} onClick={onClick} className={className} src={icon} />
      ) : (
        <></>
      )}
    </>
  );
}
