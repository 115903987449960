import { useTranslation } from "react-i18next";
import { FeaturesBlockDto } from "~/modules/pageBlocks/components/blocks/marketing/features/FeaturesBlockUtils";
import clsx from "clsx";
import { HoverEffect } from "~/components/aceternity/HoverEffect";
import HeadingBlock from "../heading/HeadingBlock";

export default function FeaturesVariantHoverEffect({ item }: { item: FeaturesBlockDto }) {
  const { t } = useTranslation();
  return (
    <section className="group container relative mx-auto space-y-12">
      <div
        className={clsx(
          "absolute left-1/2 top-1/2 -z-10 h-[400px] w-[800px] -translate-x-1/2 -translate-y-1/2 opacity-20 blur-[100px] dark:opacity-10",
          item.className
        )}
      />
      <HeadingBlock
        item={{
          style: "left",
          headline: item.headline || "",
          subheadline: item.subheadline || "",
        }}
      />
      <div className="mx-auto flex justify-center">
        <HoverEffect items={item.items} />
      </div>
    </section>
  );
}
