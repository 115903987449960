import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { HeroBlockDto } from "./HeroBlockUtils";
import { Link } from "@remix-run/react";
import clsx from "clsx";
import toast from "react-hot-toast";
import ButtonEvent from "~/components/ui/buttons/ButtonEvent";

export default function HeroVariantOffsetImage({ item }: { item: HeroBlockDto }) {
  const { t } = useTranslation();

  return (
    <div className=" -z-50 -mt-24 pt-2">
      <div className="relative isolate overflow-hidden bg-gradient-to-b from-indigo-100/20 pt-14">
        <div
          className="absolute inset-y-0 right-1/2 -z-10 -mr-96 w-[200%] origin-top-right skew-x-[-30deg] bg-white shadow-xl shadow-indigo-600/10 ring-1 ring-indigo-50 sm:-mr-80 lg:-mr-96"
          aria-hidden="true"
        />
        <div className="mx-auto max-w-7xl px-6 py-20 sm:py-20 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0 lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-x-16 lg:gap-y-6 xl:grid-cols-1 xl:grid-rows-1 xl:gap-x-8">
            {item.topText && (
              <div className="block text-sm font-semibold uppercase tracking-wide text-gray-500 sm:text-base lg:text-sm xl:text-base">
                {t(item.topText.text ?? "")}{" "}
                {item.topText.link && (
                  <Link to={item.topText.link.href ?? ""} className="text-theme-600 relative font-semibold">
                    <span className="absolute inset-0" aria-hidden="true" />
                    {t(item.topText.link.text ?? "")} <span aria-hidden="true">&rarr;</span>
                  </Link>
                )}
              </div>
            )}
          </div>
          <div className="mx-auto max-w-2xl lg:mx-0 lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-x-16 lg:gap-y-6 xl:grid-cols-1 xl:grid-rows-1 xl:gap-x-8">
            <h1 className="max-w-2xl text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl lg:col-span-2 xl:col-auto">{t(item.headline)}</h1>
            <div className="mt-6 max-w-xl lg:mt-0 xl:col-end-1 xl:row-start-1">
              <p className="text-lg leading-8 text-gray-600">{t(item.description)}</p>
              <div className="mt-10 flex items-center gap-x-6">
                <div className="flex flex-wrap justify-center gap-4">
                  {item.cta.map((item, idx) => {
                    return (
                      <Fragment key={idx}>
                        <ButtonEvent
                          event={{
                            action: "click",
                            category: "hero",
                            label: typeof item.text === "string" ? item.text : "button",
                            value: item.href || item.text,
                          }}
                          key={idx}
                          to={item.href}
                          className={clsx(
                            "w-full sm:w-auto",
                            item.isPrimary
                              ? "bg-theme-500 hover:bg-theme-600 inline-flex justify-center rounded-md border-0 px-4 py-3 text-lg text-white shadow-sm focus:outline-none"
                              : "inline-flex justify-center rounded-md border-0 bg-gray-100 px-4 py-3 text-lg text-gray-700 shadow-sm hover:bg-gray-200 focus:outline-none"
                          )}
                          onClick={() => {
                            if (item.text === "Demo") {
                              try {
                                // @ts-ignore
                                Storylane.Play({
                                  type: "popup",
                                  demo_type: "image",
                                  width: 2560,
                                  height: 1376,
                                  scale: "0.95",
                                  demo_url: "https://app.storylane.io/demo/q5hpae4vmpo7",
                                  padding_bottom: "calc(53.75% + 27px)",
                                });
                              } catch (e: any) {
                                toast.error("Could not open the product demo: " + e.message);
                              }
                            }
                          }}
                        >
                          {t(item.text)}
                        </ButtonEvent>
                      </Fragment>
                    );
                  })}
                </div>
                <div className="mt-4 space-y-3">
                  {item.bottomText && (
                    <span>
                      {t(item.bottomText.text ?? "")}{" "}
                      {item.bottomText.link?.href && (
                        <>
                          <ButtonEvent
                            event={{
                              action: "click",
                              category: "hero",
                              label: "bottom-link",
                              value: item.bottomText.link.href ?? "",
                            }}
                            to={item.bottomText.link.href ?? ""}
                            target={item.bottomText.link.target}
                            className="border-accent-500 border-b border-dashed text-sm italic text-gray-500 hover:border-dotted dark:border-cyan-300"
                          >
                            {t(item.bottomText.link.text ?? "")}
                          </ButtonEvent>
                        </>
                      )}
                    </span>
                  )}
                </div>
              </div>
              <div className="mt-4">
                <a href="https://www.producthunt.com/posts/datalang?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-datalang" target="_blank">
                  <img
                    src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=435923&theme=light"
                    alt="DataLang - Chat&#0032;with&#0032;your&#0032;Databases | Product Hunt"
                    style={{
                      width: "200px",
                      height: "54px",
                    }}
                    width="250"
                    height="54"
                  />
                </a>
              </div>
            </div>

            {/* <img
              src="https://images.unsplash.com/photo-1567532900872-f4e906cbf06a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1280&q=80"
              alt=""
              className="mt-10 aspect-[6/5] w-full max-w-lg rounded-2xl object-cover sm:mt-16 lg:mt-0 lg:max-w-none xl:row-span-2 xl:row-end-2 xl:mt-36"
            /> */}
            <div className="mt-10 aspect-[6/5] w-full max-w-lg rounded-2xl object-cover sm:mt-16 lg:mt-0 lg:max-w-none xl:row-span-2 xl:row-end-2 xl:mt-0">
              <iframe
                src={item.video}
                title={item.headline}
                loading="lazy"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
                width="100%"
                height="100%"
              ></iframe>
            </div>
          </div>
        </div>
        <div className="absolute inset-x-0 bottom-0 -z-10 h-24 bg-gradient-to-t from-white sm:h-32" />
      </div>
    </div>
  );
}
