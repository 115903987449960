"use client";

import { cn } from "~/lib/utils";
import { AnimatedBeam } from "~/components/magicui/animated-beam";
import React, { forwardRef, useRef } from "react";
import DataSourceIcons from "~/modules/datalang/components/icons/DataSourceIcons";
import IconLight from "~/assets/img/icon-light.png";

const Circle = forwardRef<HTMLDivElement, { className?: string; children?: React.ReactNode }>(({ className, children }, ref) => {
  return (
    <div
      ref={ref}
      className={cn(
        "border-border bg-background z-10 flex h-16 items-center justify-center rounded-full border-2 p-2 shadow-[0_0_20px_-12px_rgba(0,0,0,0.8)]",
        className
      )}
    >
      {children}
    </div>
  );
});

export function AnimatedBeamMultipleInputDemo() {
  const containerRef = useRef<HTMLDivElement>(null);
  const div1Ref = useRef<HTMLDivElement>(null);
  const div2Ref = useRef<HTMLDivElement>(null);
  const div3Ref = useRef<HTMLDivElement>(null);
  const div4Ref = useRef<HTMLDivElement>(null);
  const div5Ref = useRef<HTMLDivElement>(null);
  const div6Ref = useRef<HTMLDivElement>(null);
  const div7Ref = useRef<HTMLDivElement>(null);
  const div8Ref = useRef<HTMLDivElement>(null);
  const div9Ref = useRef<HTMLDivElement>(null);

  return (
    <div className="relative flex h-full w-full items-center justify-center overflow-hidden rounded-lg p-10" ref={containerRef}>
      <div className="flex h-full w-full flex-row items-stretch justify-between gap-10">
        <div className="flex flex-col justify-center gap-4">
          <Circle ref={div1Ref}>
            <DataSourceIcons type="googleSheets" className="h-9 w-9" />
          </Circle>
          <Circle ref={div2Ref}>
            <DataSourceIcons type="postgres" className="h-9 w-9" />
          </Circle>
          <Circle ref={div3Ref}>
            <DataSourceIcons type="notion" className="h-9 w-9" />
          </Circle>
          <Circle ref={div4Ref}>
            <DataSourceIcons type="file" className="h-9 w-9" />
          </Circle>
          {/* <Circle ref={div5Ref}>
            <DataSourceIcons type="website" className="h-9 w-9" />
          </Circle> */}
          {/* <Circle ref={div6Ref}>
            <DataSourceIcons type="content" className="h-9 w-9" />
          </Circle> */}
          <Circle ref={div7Ref}>
            <DataSourceIcons type="website" className="h-9 w-9" />
          </Circle>
        </div>
        <div className="flex flex-col justify-center">
          {/* <Circle ref={div8Ref} className="h-16 w-16">
            <svg role="img" viewBox="0 0 24 24" className="h-9 w-9">
              <path d="M22.2819 9.8211a5.9847 5.9847 0 0 0-.5157-4.9108 6.0462 6.0462 0 0 0-6.5098-2.9A6.0651 6.0651 0 0 0 4.9807 4.1818a5.9847 5.9847 0 0 0-3.9977 2.9 6.0462 6.0462 0 0 0 .7427 7.0966 5.98 5.98 0 0 0 .511 4.9107 6.051 6.051 0 0 0 6.5146 2.9001A5.9847 5.9847 0 0 0 13.2599 24a6.0557 6.0557 0 0 0 5.7718-4.2058 5.9894 5.9894 0 0 0 3.9977-2.9001 6.0557 6.0557 0 0 0-.7475-7.0729zm-9.022 12.6081a4.4755 4.4755 0 0 1-2.8764-1.0408l.1419-.0804 4.7783-2.7582a.7948.7948 0 0 0 .3927-.6813v-6.7369l2.02 1.1686a.071.071 0 0 1 .038.052v5.5826a4.504 4.504 0 0 1-4.4945 4.4944zm-9.6607-4.1254a4.4708 4.4708 0 0 1-.5346-3.0137l.142.0852 4.783 2.7582a.7712.7712 0 0 0 .7806 0l5.8428-3.3685v2.3324a.0804.0804 0 0 1-.0332.0615L9.74 19.9502a4.4992 4.4992 0 0 1-6.1408-1.6464zM2.3408 7.8956a4.485 4.485 0 0 1 2.3655-1.9728V11.6a.7664.7664 0 0 0 .3879.6765l5.8144 3.3543-2.0201 1.1685a.0757.0757 0 0 1-.071 0l-4.8303-2.7865A4.504 4.504 0 0 1 2.3408 7.872zm16.5963 3.8558L13.1038 8.364 15.1192 7.2a.0757.0757 0 0 1 .071 0l4.8303 2.7913a4.4944 4.4944 0 0 1-.6765 8.1042v-5.6772a.79.79 0 0 0-.407-.667zm2.0107-3.0231l-.142-.0852-4.7735-2.7818a.7759.7759 0 0 0-.7854 0L9.409 9.2297V6.8974a.0662.0662 0 0 1 .0284-.0615l4.8303-2.7866a4.4992 4.4992 0 0 1 6.6802 4.66zM8.3065 12.863l-2.02-1.1638a.0804.0804 0 0 1-.038-.0567V6.0742a4.4992 4.4992 0 0 1 7.3757-3.4537l-.142.0805L8.704 5.459a.7948.7948 0 0 0-.3927.6813zm1.0976-2.3654l2.602-1.4998 2.6069 1.4998v2.9994l-2.5974 1.4997-2.6067-1.4997Z" />
            </svg>
          </Circle> */}
          <div ref={div8Ref} className="animate-spin-slow z-10 h-16 w-16 p-2">
            <svg role="img" viewBox="0 0 24 24" className="">
              <path d="M22.2819 9.8211a5.9847 5.9847 0 0 0-.5157-4.9108 6.0462 6.0462 0 0 0-6.5098-2.9A6.0651 6.0651 0 0 0 4.9807 4.1818a5.9847 5.9847 0 0 0-3.9977 2.9 6.0462 6.0462 0 0 0 .7427 7.0966 5.98 5.98 0 0 0 .511 4.9107 6.051 6.051 0 0 0 6.5146 2.9001A5.9847 5.9847 0 0 0 13.2599 24a6.0557 6.0557 0 0 0 5.7718-4.2058 5.9894 5.9894 0 0 0 3.9977-2.9001 6.0557 6.0557 0 0 0-.7475-7.0729zm-9.022 12.6081a4.4755 4.4755 0 0 1-2.8764-1.0408l.1419-.0804 4.7783-2.7582a.7948.7948 0 0 0 .3927-.6813v-6.7369l2.02 1.1686a.071.071 0 0 1 .038.052v5.5826a4.504 4.504 0 0 1-4.4945 4.4944zm-9.6607-4.1254a4.4708 4.4708 0 0 1-.5346-3.0137l.142.0852 4.783 2.7582a.7712.7712 0 0 0 .7806 0l5.8428-3.3685v2.3324a.0804.0804 0 0 1-.0332.0615L9.74 19.9502a4.4992 4.4992 0 0 1-6.1408-1.6464zM2.3408 7.8956a4.485 4.485 0 0 1 2.3655-1.9728V11.6a.7664.7664 0 0 0 .3879.6765l5.8144 3.3543-2.0201 1.1685a.0757.0757 0 0 1-.071 0l-4.8303-2.7865A4.504 4.504 0 0 1 2.3408 7.872zm16.5963 3.8558L13.1038 8.364 15.1192 7.2a.0757.0757 0 0 1 .071 0l4.8303 2.7913a4.4944 4.4944 0 0 1-.6765 8.1042v-5.6772a.79.79 0 0 0-.407-.667zm2.0107-3.0231l-.142-.0852-4.7735-2.7818a.7759.7759 0 0 0-.7854 0L9.409 9.2297V6.8974a.0662.0662 0 0 1 .0284-.0615l4.8303-2.7866a4.4992 4.4992 0 0 1 6.6802 4.66zM8.3065 12.863l-2.02-1.1638a.0804.0804 0 0 1-.038-.0567V6.0742a4.4992 4.4992 0 0 1 7.3757-3.4537l-.142.0805L8.704 5.459a.7948.7948 0 0 0-.3927.6813zm1.0976-2.3654l2.602-1.4998 2.6069 1.4998v2.9994l-2.5974 1.4997-2.6067-1.4997Z" />
            </svg>
          </div>
        </div>
        <div className="flex flex-col justify-center">
          {/* <Circle ref={div9Ref} className="h-16 w-16"> */}
          {/* <Icon size="h-6" /> */}
          <div ref={div9Ref} className="bg-background z-10 h-16 w-16">
            <img className="h-16 w-16" src={IconLight} alt="Logo" />
          </div>
          {/* </Circle> */}
        </div>
      </div>

      <AnimatedBeam containerRef={containerRef} fromRef={div1Ref} toRef={div8Ref} />
      <AnimatedBeam containerRef={containerRef} fromRef={div2Ref} toRef={div8Ref} />
      <AnimatedBeam containerRef={containerRef} fromRef={div3Ref} toRef={div8Ref} />
      <AnimatedBeam containerRef={containerRef} fromRef={div4Ref} toRef={div8Ref} />
      <AnimatedBeam containerRef={containerRef} fromRef={div5Ref} toRef={div8Ref} />
      <AnimatedBeam containerRef={containerRef} fromRef={div6Ref} toRef={div8Ref} />
      <AnimatedBeam containerRef={containerRef} fromRef={div7Ref} toRef={div8Ref} />
      <AnimatedBeam containerRef={containerRef} fromRef={div8Ref} toRef={div9Ref} />
    </div>
  );
}
