import { Fragment } from "react";
import ButtonEvent from "~/components/ui/buttons/ButtonEvent";
import ProductHuntBadge from "./ProductHuntBadge";
import { useRootData } from "~/utils/data/useRootData";

const UNEED_BADGE = false;
const PRODUCTHUNT_BADGE = false;

export default function LaunchBadges({ theme }: { theme?: "light" | "dark" | "neutral" }) {
  const rootData = useRootData();
  if (!UNEED_BADGE && !PRODUCTHUNT_BADGE) return null;
  return (
    <Fragment>
      <div className="mx-auto mb-6 flex justify-center space-x-2 text-center">
        {UNEED_BADGE && (
          <ButtonEvent
            event={{ action: "click", category: "hero", label: "uneed", value: "https://www.uneed.best/tool/datalang" }}
            to="https://www.uneed.best/tool/datalang"
            target="_blank"
          >
            <img src="https://www.uneed.best/EMBED1A.png" alt="Uneed Embed Badge" className="h-12" />
          </ButtonEvent>
        )}

        {PRODUCTHUNT_BADGE && <ProductHuntBadge theme={theme || rootData.userSession.lightOrDarkMode === "dark" ? "dark" : "light"} />}
      </div>
    </Fragment>
  );
}
